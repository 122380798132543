import React, {useState} from "react"
import IMAGE from '../../images/about-1.jpg'
import Icon from "../icon";
import { connect } from "react-redux"
import Formulario from '../Contact.jsx'

import {getDescription,descriptionIsNull, getCover, getMessageWhatsapp} from '../../helpers/helper.rendering'
import {getTags,getBluePrints, getFakeAddres, getLocation,getTour, getLastVideo, getOperations, getType} from '../../helpers/helper.properties'
import Breadcrumbs from "../breadcrumbs";

import Map from '../map'

import SimpleReactLightbox, { SRLWrapper, useLightbox }from 'simple-react-lightbox'
import { graphql, useStaticQuery } from 'gatsby';

import Loading from '../../components/Loading'
import FilesPro from "../Emprendimiento/files-pro";


const options = {
    settings: {
        overlayColor: "rgba(0, 0, 0, 0.9)",
        autoplaySpeed: 1500,
        transitionSpeed: 900,
    },
    buttons: {
        backgroundColor: "rgba(0, 0, 0, 0.9)",
        iconColor: "rgba(255,255,255, 1)",
    },
    caption: {
        captionColor: "#000",
        captionFontFamily: "Raleway, sans-serif",
        captionFontWeight: "300",
        captionTextTransform: "uppercase",
    }
};


const About = ({property,loading,dispatch,development}) => {
    const [charMax,setCharMax] = useState(1000)
    const { openLightbox, closeLightbox } = useLightbox()
    const { realEstate } = useStaticQuery(graphql`
    query {
        realEstate {
            name
            global_email
            global_phone
            branch_office{
                name
              contact {
                phone
                mail
                whatsapp
              }
            }
        }
  }`)
    return !loading ? (
        <section class="ficha-about prop px-lg-5 pt-1">
            <div class="container-fluid">
                {/* <hr /> */}
                <div class="row no-gutters pb-2">
                    <div class="col-12 mb-5">
                        <h4 class="mt-2 mb-5">Descripción de la propiedad.</h4>
                        <p class="description-ficha">
                            {/* { (property && property?.description) 
                                ? property?.description?.replaceAll("&nbsp;","") : null } */}
                        { property && getDescription(property,999999).map((description,index) => (
                          <>
                            {description}
                            <br />
                          </> ))}
                        {/* {charMax === 1000 && !descriptionIsNull(property) && <span class="d-block text-right text-expand mt-3"><a onClick={() => setCharMax(9999)}>Ver más</a></span>}
                        {charMax === 9999 && <span class="d-block text-right text-expand mt-3"><a onClick={() => setCharMax(1000)}>Ver menos</a></span>} */}
                        </p>
                    </div>
                    <div class={"col-12 mt-5 " + (getTags(property).length > 0 ? '' : 'd-none') }>
                        <h4 class="mb-5 mt-5 mt-lg-auto">Detalles</h4>
                        <div class={"row no-gutters amenities " + (property?.files ? "mb-4" : "")}>
                            {property && getTags(property).map((tag,index) => (
                                <div class="col-6 col-lg-3 mb-3 item" key={index}>
                                    <i class="icon-checked"></i>
                                    <p>{tag.name}</p>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
                <div class="row ubicacion-main">
                    { property?.files ?
                        <div className="col-12">
                            <FilesPro files={property.files} />
                        </div> : null }
                    <div class="col-12">
                        <h4 class={" mb-5 " + (property?.files ? "mt-4" : "")}>Ubicación</h4>
                    </div>
                    <div class="col-12">
                        <Map coordenadas={{ geo_lat: property.geo_lat, geo_long: property.geo_long }} />
                    </div>
                </div>
                <div class="row contacto-main pb-5">
                    <div class="col-lg-5 col-12">
                        <h2>Consultanos por esta  propiedad </h2>
                        <div class="content-contact">
                            <a class="mailto" href={"mailto:" + realEstate.email}><h5>{realEstate.email}</h5></a>
                            <div class="row mb-lg-3">
                                <div class="col-lg-8">
                                    <a class="mailto mb-4 d-block hover-service-link" target="_blank" href={"mailto:" + realEstate.global_email}><h5>{realEstate.global_email}</h5></a>
                                    <div class="whatsapp my-3">
                                        <h5 className="mb-3">Whatsapp</h5>
                                        {realEstate.branch_office.length > 0 &&
                                            realEstate.branch_office.map((branch,index) => (
                                            <div class="d-block flex-wrap mt-3 mt-lg-0" key={index}>
                                                { branch.contact.whatsapp && <p className="d-none d-lg-flex flex-wrap"><div className="fixed-width"> {branch.name}</div> <a className='ms-4' href={"https://web.whatsapp.com/send?text=" + getMessageWhatsapp(property) + "&phone=+549" + branch.contact.whatsapp} target="_blank">{branch.contact.whatsapp}</a></p>}  
                                                { branch.contact.whatsapp && <p className="d-flex d-lg-none flex-wrap"><div className="fixed-width">{branch.name}</div> <a className='ms-4' href={"https://api.whatsapp.com/send?text=" + getMessageWhatsapp(property) + "&phone=+549" + branch.contact.whatsapp} target="_blank">{branch.contact.whatsapp}</a></p>}  
                                            </div>))} 
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-7 col-12 mt-4 mt-lg-0">
                        <Formulario property={property} from={'property'} />
                        <div id="alert-message"></div>
                    </div>
                </div>
            </div>
        </section>
    ):
    <>
      <Loading absolute={true}/>
    </>
}

export default connect(state => ({
    property: state.properties.property,
    loading: state.properties.loading,
}),null)(About);